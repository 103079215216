import { Box } from '@mui/joy';
import { PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import Footer from './Footer';
import MySnackbar from './MySnackbar';
import MainSidebar from './MainSidebar';
import MainHeader from './MainHeader';

interface Props {
  title: string;
}

function MainPageTemplate({
  title = 'Theora Bed Sensor',
  children,
}: PropsWithChildren<Props>) {
  return (
    <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <MainSidebar />
      <MainHeader />
      <Box
        component="main"
        className="MainContent"
        sx={{
          pt: { xs: 'calc(12px + var(--Header-height))', md: 3 },
          pb: { xs: 2, sm: 2, md: 3 },
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          height: '100dvh',
          gap: 1,
          overflow: 'auto',
        }}
      >
        <Box sx={{ flex: 1 }}>{children}</Box>
        <Footer />
      </Box>
      <MySnackbar />
    </Box>
  );
}

export default MainPageTemplate;
