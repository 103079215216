import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../store/root';

function useBedSensor(_id: string | undefined, queryId: string) {
  const bedSensor = useSelector((store: RootState) => {
    if (_id) {
      return store.bedSensors[queryId]?.[_id];
    }
    return undefined;
  }, shallowEqual);
  return bedSensor;
}

export default useBedSensor;
