import { Box, Card, Grid, Skeleton, Typography } from '@mui/joy';
import { useMemo } from 'react';
import MyGridItem, { MyDetailItem } from '../../components/MyGridItem';
import OpenButton from '../../components/OpenButton';
import EditTextInner from '../../components/edit-inner/EditTextInner';
import EditSomeBedSensorLine from './EditSomeBedSensorLine';
import { BedSensorNode } from '../../__generated__/graphql';

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'long',
});

interface Props {
  bedSensor: BedSensorNode | undefined;
  loading: boolean;
}

function BedSensorDetailsCard({ loading, bedSensor }: Props) {
  const details = useMemo<MyDetailItem[] | undefined>(() => {
    if (bedSensor) {
      return [
        {
          key: 'serialNo',
          label: 'Serial No.',
          value: bedSensor.serialNo,
          width: 3,
        },
        {
          key: 'fwVersion',
          label: 'Firmware Version',
          value: bedSensor.fwVersion,
          width: 3,
        },
        {
          key: 'macAddress',
          label: 'MAC Address',
          value: bedSensor.macAddress,
          width: 3,
        },
        {
          key: 'lastTimestampMs',
          label: 'Last Message',
          value: dateFormat.format(new Date(bedSensor.lastTimestampMs)),
          width: 12,
        },
        {
          key: 'notes',
          label: 'Notes',
          width: 12,
          component: (
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ flex: 1 }}>
                {(bedSensor.notes || 'Add a description ...')
                  .split('\n')
                  .map((line: any, index: number) => (
                    <Typography key={index} level="body-md">
                      {line}
                    </Typography>
                  ))}
              </Box>
              {bedSensor?._id && (
                <EditSomeBedSensorLine
                  _id={bedSensor._id}
                  EditForm={
                    <EditTextInner
                      defaultValues={{ notes: bedSensor.notes || '' }}
                      title="Update Description"
                      name="notes"
                      label="Notes"
                      rules={{ required: 'This field is required' }}
                    />
                  }
                  OpenButton={<OpenButton size="sm" />}
                />
              )}
            </Box>
          ),
        },
      ].filter((i) => i) as MyDetailItem[];
    }
    return undefined;
  }, [bedSensor]);
  return (
    <Card
      sx={{
        p: 2,
        pt: 4,
        pb: 4,
        mr: 'auto',
        ml: 'auto',
        mt: 1,
        mb: 2,
      }}
    >
      {details ? (
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {details.map((item) => (
            <Grid key={item.key} xs={item.width} lg={item.lgWidth}>
              <MyGridItem item={item} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <>
          {loading ? (
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
              {Array(18)
                .fill(0)
                .map((_, index) => (
                  <Grid key={index} xs={4} lg={3}>
                    <Skeleton
                      variant="rectangular"
                      height={24}
                      sx={{ mb: 1, mt: 1 }}
                    />
                  </Grid>
                ))}
            </Grid>
          ) : (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 6,
              }}
            >
              <Typography sx={{ textAlign: 'center' }}>
                Nothing to see here.
              </Typography>
            </Box>
          )}
        </>
      )}
    </Card>
  );
}

export default BedSensorDetailsCard;
