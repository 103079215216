import { gql, useSubscription } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  BedSensorNode,
  BedSensorUpdatesSubscription,
} from '../../__generated__/graphql';
import { updateBedSensor } from '../../store/bed-sensor-slice';

const bedSensorUpdatesSubscription = gql`
  subscription BedSensorUpdates($_id: ID!) {
    bedSensorUpdates(_id: $_id) {
      _id
      crud
      new {
        _id
        name
        serialNo
        macAddress
        fwVersion
        lastTimestampMs
        notes
        createdAt
        updatedAt
        deletedAt
      }
    }
  }
`;

function useBedSensorUpdates(_id: string | undefined, queryId = 'default') {
  const dispatch = useDispatch();
  useSubscription<BedSensorUpdatesSubscription>(bedSensorUpdatesSubscription, {
    skip: !_id,
    variables: {
      _id,
    },
    fetchPolicy: 'no-cache',
    onSubscriptionData: ({ client, subscriptionData: result }) => {
      try {
        const update = result?.data?.bedSensorUpdates;
        if (update?.new) {
          switch (update.crud) {
            case 'update':
              dispatch(updateBedSensor(update.new as BedSensorNode, queryId));
              break;
            default:
              break;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  });
}

export default useBedSensorUpdates;
