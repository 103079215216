import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/joy';
import { passwordStrength } from 'check-password-strength';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import logo from '../../assets/long-bed-sensor-logo.svg';
import PasswordFieldElement from '../../components/PasswordFieldElement';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import { showMessage } from '../../store/snackbar-slice';
import usePasswordResetDetails from './use-password-reset-details';

const webPasswordResetMutation = gql`
  mutation WebPasswordReset($token: String!, $password: String!) {
    webPasswordReset(token: $token, password: $password)
  }
`;

function PasswordResetPage() {
  const { token } = useParams();
  const [saving, setSaving] = useState(false);
  const [passwordReset] = useMutation(webPasswordResetMutation);
  const { user, loading } = usePasswordResetDetails(token);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values) => {
      setSaving(true);
      try {
        const { password } = _values;
        await passwordReset({
          variables: {
            token,
            password,
          },
        });
        dispatch(
          showMessage({
            _id: 'password-reset',
            severity: 'success',
            message: 'Success',
          }),
        );
        navigate(`/password-reset/success/${token}`);
      } catch (err: any) {
        console.error(err);
        if (err.message.includes('Not Authorized')) {
          dispatch(
            showMessage({
              _id: 'password-reset',
              severity: 'danger',
              message: err.message,
            }),
          );
        } else {
          dispatch(
            showMessage({
              _id: 'password-reset',
              severity: 'danger',
              message: 'There was an error reseting your password.',
            }),
          );
        }
      }
      setSaving(false);
    },
    [passwordReset, dispatch, navigate, token],
  );

  return (
    <PublicPageTemplate title="Reset Password">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Theora Bed Sensor"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography level="h1" style={{ textAlign: 'center' }}>
            Password Reset
          </Typography>
          <Typography level="h3" style={{ textAlign: 'center' }}>
            {user?.name}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Typography level="body-lg" sx={{ textAlign: 'center', mb: 4 }}>
              Create a new password.
            </Typography>
            <PasswordFieldElement
              control={control}
              name="password"
              label="Password"
              caption="Must be reasonably long and/or complex"
              rules={{
                required: 'This field is required',
                validate: (value: string) => {
                  const result = passwordStrength(value);
                  if (result.id < 3) {
                    return 'Weak password, try making it longer, including numbers and symbols, or mixing upper and lower case.';
                  }
                  return undefined;
                },
              }}
              sx={{ mb: 2 }}
            />
            <PasswordFieldElement
              control={control}
              name="confirm"
              label="Confirm Password"
              caption="Enter your password again."
              rules={{
                required: 'This field is required',
                validate: (value: string, values: any) => {
                  if (value !== values.password) {
                    return 'Your passwords do not match.';
                  }
                  return undefined;
                },
              }}
            />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading || saving}
                color="primary"
                size="lg"
                sx={{ width: 200 }}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default PasswordResetPage;
