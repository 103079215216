import { parsePhoneNumber } from 'awesome-phonenumber';
import { Address } from '../store/auth-slice';
import ShortUniqueId from 'short-unique-id';

const { randomUUID } = new ShortUniqueId({ length: 16 });

export function generateUuid() {
  return randomUUID();
}

export function isPhoneNumberValid(phoneNumber: string) {
  let trimmed = phoneNumber.trim();
  if (trimmed) {
    let parsed;
    if (trimmed.startsWith('+')) {
      parsed = parsePhoneNumber(trimmed);
    } else {
      parsed = parsePhoneNumber(trimmed, {
        regionCode: 'US',
      });
    }
    if (!parsed.valid) {
      return true;
    }
    return false;
  }
  return undefined;
}

export function formatPhoneNumberForStorage(
  phoneNumber: string | null | undefined,
) {
  let trimmed = phoneNumber?.trim();
  if (trimmed) {
    let parsed;
    if (trimmed.startsWith('+')) {
      parsed = parsePhoneNumber(trimmed);
    } else {
      parsed = parsePhoneNumber(trimmed, {
        regionCode: 'US',
      });
    }
    if (parsed.valid && parsed?.number?.e164) {
      return parsed?.number?.e164;
    }
  }
  return null;
}

export function formatPhoneNumberForDisplay(
  phoneNumber: string | null | undefined,
) {
  if (phoneNumber) {
    let parsed;
    if (phoneNumber.startsWith('+')) {
      parsed = parsePhoneNumber(phoneNumber);
    } else {
      parsed = parsePhoneNumber(phoneNumber, {
        regionCode: 'US',
      });
    }
    return parsed && parsed.number && parsed.number.international;
  }
  return null;
}

export function formatAddress(address: Address | null | undefined) {
  return [
    address?.address1,
    address?.address2,
    [
      [address?.city, address?.state].filter((a) => a).join(', '),
      address?.zipCode,
    ]
      .filter((a) => a)
      .join(' '),
    address?.country,
  ]
    .filter((a) => a)
    .join('\n');
}

export const hashCode = (s: string) =>
  Array.from(s).reduce((prev, curr) => {
    const hash = (prev << 5) - prev + curr.charCodeAt(0);
    return hash & hash;
  }, 0);

export function notNullOrUndefined(attr: any) {
  return attr !== undefined && attr !== null;
}
