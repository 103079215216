import { gql } from '@apollo/client';
import { AllHistoryQueryQuery } from '../../__generated__/graphql';

export const allHistoryQuery = gql`
  query AllHistoryQuery(
    $first: Int
    $after: String
    $filters: HistoryFilters
    $sortBy: [SortBy]
  ) {
    allHistory(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          userId
          userInviteId
          typename
          changedAt
          changedBy
          changedByName
          changes
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllHistory = (data: AllHistoryQueryQuery) =>
  data.allHistory;
