import { gql } from '@apollo/client';
import { AllBedSensorDataQuery } from '../../__generated__/graphql';

export const allBedSensorDataQuery = gql`
  query AllBedSensorData(
    $first: Int
    $after: String
    $filters: BedSensorDataFilters
    $sortBy: [SortBy]
  ) {
    allBedSensorData(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          t
          n
          m
          p
          s
          f
          b
          h
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllBedSensorData = (data: AllBedSensorDataQuery) =>
  data.allBedSensorData;
