import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from '@mui/joy';
import { useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  AllBedSensorsQueryQuery,
  BedSensorNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import MyTable, { AllColumn } from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import { allBedSensorsQuery, extractAllBedSensors } from './constants';

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'long',
});

const queryId = 'bedSensorsTable';

function BedSensorsTable() {
  const filters = useRef({});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: rows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllBedSensorsQueryQuery, BedSensorNode>({
    query: allBedSensorsQuery,
    extract: extractAllBedSensors,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const allFilters: AllFilter[] = useMemo(() => {
    return [];
  }, []);

  const allColumns: AllColumn[] = useMemo(
    () => [
      {
        key: 'go',
        title: '',
        component: (row: any) => {
          return (
            <IconButton
              component={Link}
              to={`/bed-sensors/view/${row._id}/data`}
            >
              <VisibilityIcon />
            </IconButton>
          );
        },
        sortable: false,
        width: 60,
        // style: (row: any) => {
        //   const color = 'neutral';
        //   return {
        //     borderLeft: `8px solid var(--joy-palette-${color}-outlinedBorder`,
        //     zIndex: 1,
        //   };
        // },
      },
      {
        key: 'macAddress',
        title: 'Mac Address',
        component: (row: any) => row.macAddress,
        sortable: true,
        width: 120,
      },
      {
        key: 'name',
        title: 'Name',
        component: (row: any) => row.name,
        sortable: true,
        width: 120,
      },
      {
        key: 'notes',
        title: 'Description',
        component: (row: any) => row.notes,
        sortable: true,
        width: 200,
      },
      {
        key: 'serialNo',
        title: 'Serial No.',
        component: (row: any) => row.serialNo,
        sortable: true,
        width: 100,
      },
      {
        key: 'fwVersion',
        title: 'Firmware Version',
        component: (row: any) => row.serialNo,
        sortable: true,
        width: 160,
      },
      {
        key: 'lastTimestampMs',
        title: 'Last Message',
        component: (row: any) =>
          dateFormat.format(new Date(row.lastTimestampMs)),
        sortable: true,
        width: 140,
      },
    ],
    [],
  );

  return (
    <MyTable
      initialOrder={initialOrder}
      rows={rows}
      sortBy={sortBy}
      refetch={refetch}
      filters={filters}
      allFilters={allFilters}
      search={search}
      allColumns={allColumns}
      hasNextPage={!!hasNextPage}
      next={next}
      loading={loading}
      hasFullText={true}
    />
  );
}

export default BedSensorsTable;
