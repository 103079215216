import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { BedSensorNode } from '../__generated__/graphql';

interface InitialState {
  [key: string]: {
    [key: string]: BedSensorNode;
  };
}

interface UpdateBedSensorPayload {
  queryId: string;
  bedSensor: BedSensorNode;
}

interface DeleteBedSensorPayload {
  queryId: string;
  _id: string;
}

const initialState: InitialState = {
  default: {},
};

const bedSensorsSlice = createSlice({
  name: 'bedSensors',
  initialState,
  reducers: {
    updateBedSensor: {
      reducer(state, action: PayloadAction<UpdateBedSensorPayload>) {
        state[action.payload.queryId] = {
          ...state[action.payload.queryId],
          [action.payload.bedSensor._id]: action.payload.bedSensor,
        };
      },
      prepare(bedSensor: BedSensorNode, queryId = 'default') {
        return { payload: { bedSensor, queryId } };
      },
    },
    deleteBedSensor: {
      reducer(state, action: PayloadAction<DeleteBedSensorPayload>) {
        const { [action.payload._id]: removing, ...remaining } =
          state[action.payload.queryId];
        state[action.payload.queryId] = remaining;
      },
      prepare(_id: string, queryId = 'default') {
        return { payload: { _id, queryId } };
      },
    },
  },
});

export const { updateBedSensor, deleteBedSensor } = bedSensorsSlice.actions;
export default bedSensorsSlice.reducer;
