import { Box, Button, Card, Typography } from '@mui/joy';
import logo from '../assets/long-bed-sensor-logo.svg';
import { Link } from 'react-router-dom';
import PublicPageTemplate from '../components/PublicPageTemplate';

function HomePage() {
  return (
    <PublicPageTemplate title="Theora Bed Sensor">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
          // background: 'linear-gradient(45deg, rgba(242,242,242,1) 40%, rgba(255,255,255,1) 60%, rgba(255,235,235,1) 100%)',
        }}
      >
        <img
          src={logo}
          alt="Theora Bed Sensor"
          style={{
            marginTop: 120,
            width: 500,
            maxWidth: '90vw',
            marginBottom: 48,
          }}
        />
        <Button variant="outlined" component={Link} to="/sign-in" size="lg">
          Enter
        </Button>
      </Box>
    </PublicPageTemplate>
  );
}

export default HomePage;
