import { gql, useSubscription } from '@apollo/client';
import {
  BedSensorDataNode,
  BedSensorDataUpdatesSubscription,
} from '../../__generated__/graphql';

const bedSensorDataUpdatesSubscription = gql`
  subscription BedSensorDataUpdates($filters: BedSensorDataFilters!) {
    bedSensorDataUpdates(filters: $filters) {
      _id
      crud
      new {
        _id
        t
        n
        m
        p
        s
        f
        b
        h
      }
    }
  }
`;

function useBedSensorDataUpdates(
  filters: any,
  onUpdate: (bs: BedSensorDataNode) => void,
) {
  useSubscription<BedSensorDataUpdatesSubscription>(
    bedSensorDataUpdatesSubscription,
    {
      skip: !filters,
      variables: {
        filters,
      },
      fetchPolicy: 'no-cache',
      onSubscriptionData: ({ client, subscriptionData: result }: any) => {
        try {
          const update = result?.data?.bedSensorDataUpdates;
          if (update?.new) {
            switch (update.crud) {
              case 'insert':
                onUpdate(update.new as BedSensorDataNode);
                break;
              default:
                break;
            }
          }
        } catch (err) {
          console.error(err);
        }
      },
    },
  );
}

export default useBedSensorDataUpdates;
