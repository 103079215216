import { useCallback, useEffect, useMemo, useRef } from 'react';
import {
  AllBedSensorDataQuery,
  BedSensorDataNode,
  SortBy,
  SortByDirection,
} from '../../__generated__/graphql';
import MyTable, { AllColumn } from '../../components/my-table/MyTable';
import { AllFilter } from '../../components/my-table/TableFilters';
import settings from '../../settings';
import { useManyRemote } from '../../shared/use-many-remote';
import useSafeState from '../../shared/use-safe-state';
import { allBedSensorDataQuery, extractAllBedSensorData } from './constants';
import useBedSensorDataUpdates from './use-bs-data-updates';

const initialOrder: SortBy = {
  key: 'updatedAt',
  order: SortByDirection.Desc,
};

const dateFormat = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'short',
  timeStyle: 'long',
});

const queryId = 'bedSensorDataTable';

interface Props {
  filters: any;
}

function BedSensorDatasTable({ filters: parentFilters }: Props) {
  const filters = useRef(parentFilters || {});
  const sortBy = useRef<SortBy[]>([initialOrder]);

  const {
    loading,
    data: startRows,
    search,
    hasNextPage,
    next,
    refetch,
    reset,
  } = useManyRemote<AllBedSensorDataQuery, BedSensorDataNode>({
    query: allBedSensorDataQuery,
    extract: extractAllBedSensorData,
    first: settings.querySize,
    filters: filters.current,
    sortBy: sortBy.current,
    fetchPolicy: 'network-only',
    queryId,
  });

  const [rows, setRows, _rows] = useSafeState<BedSensorDataNode[]>([]);
  useEffect(() => {
    setRows(startRows);
  }, [startRows, setRows]);

  const onUpdate = useCallback(
    (bs: BedSensorDataNode) => {
      setRows([bs, ..._rows.current]);
    },
    [setRows, _rows],
  );

  useBedSensorDataUpdates(parentFilters, onUpdate);

  const allFilters: AllFilter[] = useMemo(() => {
    return [];
  }, []);

  const allColumns: AllColumn[] = useMemo(
    () => [
      {
        key: 't',
        title: 'Timestamp',
        component: (row: any) => dateFormat.format(new Date(row.t)),
        sortable: true,
        width: 160,
      },
      {
        key: 'p',
        title: 'Pose',
        component: (row: any) => row.p,
        sortable: true,
        width: 120,
      },
      {
        key: 'b',
        title: 'Breathing Rate',
        component: (row: any) => row.b,
        sortable: true,
        width: 120,
      },
      {
        key: 'h',
        title: 'Heart Rate',
        component: (row: any) => row.h,
        sortable: true,
        width: 120,
      },
    ],
    [],
  );

  return (
    <MyTable
      initialOrder={initialOrder}
      rows={rows}
      sortBy={sortBy}
      refetch={refetch}
      filters={filters}
      allFilters={allFilters}
      // search={search}
      allColumns={allColumns}
      hasNextPage={!!hasNextPage}
      next={next}
      loading={loading}
      hasFullText={true}
      parentFilters={parentFilters}
    />
  );
}

export default BedSensorDatasTable;
