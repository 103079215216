import { gql, useMutation } from '@apollo/client';
import { Modal, ModalClose, ModalDialog } from '@mui/joy';
import React, { ReactElement, useCallback, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Maybe } from '../../__generated__/graphql';
import { showMessage } from '../../store/snackbar-slice';

const updateBedSensorMutation = gql`
  mutation UpdateBedSensor($bedSensor: UpdateBedSensorInput!) {
    updateBedSensor(bedSensor: $bedSensor)
  }
`;

interface Props {
  _id: string | undefined;
  EditForm: ReactElement;
  OpenButton: Maybe<ReactElement>;
}

function EditSomeBedSensorLine({ _id, EditForm, OpenButton }: Props) {
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updateBedSensor] = useMutation(updateBedSensorMutation);

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<any> = useCallback(
    async (_values: any) => {
      setSaving(true);
      try {
        const { ...values } = _values;
        await updateBedSensor({
          variables: {
            bedSensor: {
              _id,
              ...values,
            },
          },
        });
        dispatch(
          showMessage({
            _id: 'edit-bed-sensor',
            severity: 'success',
            message: 'Success',
          }),
        );
        setOpen(false);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'edit-bed-sensor',
            severity: 'danger',
            message: 'There was an error saving your changes.',
          }),
        );
      }
      setSaving(false);
    },
    [updateBedSensor, dispatch, _id],
  );

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog sx={{ p: 4, width: 600, maxWidth: '90uvw' }}>
          <ModalClose />
          {React.cloneElement(EditForm, {
            saving,
            onCancel: () => setOpen(false),
            onSubmit,
          })}
        </ModalDialog>
      </Modal>
      {OpenButton &&
        React.cloneElement(OpenButton, {
          onOpen: () => setOpen(true),
        })}
    </>
  );
}

export default EditSomeBedSensorLine;
