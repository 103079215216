import { Box, Link, Typography } from '@mui/joy';
import Version from './Version';

function Footer() {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 32,
        paddingRight: 32,
        paddingTop: 16,
        paddingBottom: 16,
      }}
    >
      <Box>
        <Typography>Clairvoyant Networks, Inc ©2024</Typography>
      </Box>
      <Box>
        <Version />
      </Box>
    </Box>
  );
}

export default Footer;
