import LocalHotelRoundedIcon from '@mui/icons-material/LocalHotelRounded';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
  tabClasses,
} from '@mui/joy';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BedSensorQueryQuery } from '../../__generated__/graphql';
import MainPageTemplate from '../../components/MainPageTemplate';
import useOne from '../../shared/use-one';
import { updateBedSensor } from '../../store/bed-sensor-slice';
import BedSensorDatasTable from '../bed-sensor-data/BedSensorsTable';
import BedSensorDetailsCard from './BedSensorDetailsCard';
import { bedSensorQuery, extractBedSensor } from './constants';
import useBedSensor from './use-bed-sensor';
import useBedSensorUpdates from './use-bs-updates';

const queryId = 'default';

function ViewBedSensorPage() {
  const { _id, tab } = useParams();
  const { loading } = useOne<BedSensorQueryQuery>({
    extract: extractBedSensor,
    fetchPolicy: 'network-only',
    variables: {
      _id,
    },
    query: bedSensorQuery,
    queryId,
    updateAction: updateBedSensor,
  });
  useBedSensorUpdates(_id, queryId);
  const bedSensor = useBedSensor(_id, queryId);
  const title = useMemo(() => {
    if (bedSensor) {
      return (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {/* <Typography>{`${bedSensor.macAddress} (${bedSensor.serialNo})`}</Typography> */}
          <LocalHotelRoundedIcon style={{ fontSize: 48, marginRight: 16 }} />
          <Typography level="h1">{`${bedSensor.name}`}</Typography>
        </Box>
      );
    }
    return <Typography level="h1">Bed Sensor</Typography>;
  }, [bedSensor]);

  return (
    <MainPageTemplate title="Service Requests">
      <Box sx={{ pt: 2, pb: 2, pl: 2, pr: 2 }}>
        <Box sx={{ pl: 2, pr: 2 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              component={Link}
              to="/bed-sensors"
              sx={{ mr: 1, textDecoration: 'none' }}
            >
              Bed Sensors
            </Typography>
            <Typography
              color="primary"
              fontWeight={500}
              level="body-md"
              sx={{ mr: 1 }}
            >
              /
            </Typography>
            <Typography color="primary" fontWeight={500} level="body-md">
              View Bed Sensor
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              pt: 2,
              pb: 1,
              justifyContent: 'space-between',
            }}
          >
            <Box>{title}</Box>
          </Box>
          <BedSensorDetailsCard bedSensor={bedSensor} loading={loading} />
        </Box>

        <Tabs
          value={tab}
          sx={{
            bgcolor: 'transparent',
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            sx={{
              pl: 0,
              justifyContent: 'left',
              [`&& .${tabClasses.root}`]: {
                fontWeight: '600',
                flex: 'initial',
                color: 'text.tertiary',
                [`&.${tabClasses.selected}`]: {
                  bgcolor: 'transparent',
                  color: 'text.primary',
                  '&::after': {
                    height: '2px',
                    bgcolor: 'primary.500',
                  },
                },
              },
            }}
          >
            <Tab
              sx={{ borderRadius: '6px 6px 0 0' }}
              indicatorInset
              value="data"
              component={Link}
              to={`/bed-sensors/view/${_id}/data`}
            >
              Data
            </Tab>
          </TabList>
          <TabPanel value="data">
            <BedSensorDatasTable filters={{ bedSensorId: _id }} />
          </TabPanel>
        </Tabs>
      </Box>
    </MainPageTemplate>
  );
}

export default ViewBedSensorPage;
