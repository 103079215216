import { gql, useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashboardPage from '../pages/DashboardPage';
import SettingsPage from '../pages/SettingsPage';
import AllBedSensorsPage from '../pages/bed-sensors/AllBedSensorsPage';
import ProfilePage from '../pages/profile/ProfilePage';
import AllUsersPage from '../pages/users/AllUsersPage';
import ViewUser from '../pages/users/ViewUser';
import useProfileUpdates from '../shared/use-profile-updates';
import { updateProfile } from '../store/auth-slice';
import { showMessage } from '../store/snackbar-slice';
import ViewBedSensorPage from '../pages/bed-sensors/ViewBedSensorPage';

const profileQuery = gql`
  query MyProfile {
    myProfile {
      _id
      username
      name
      roleIds
      email
      emailVerified
      phone
      phoneVerified
      address {
        address1
        address2
        city
        state
        county
        country
        zipCode
      }
      profilePhotoId
      profilePhotoThumbUrl
      createdAt
      updatedAt
    }
  }
`;

function MainRouter() {
  const client = useApolloClient();
  const dispatch = useDispatch();
  useEffect(() => {
    async function doAsyncStuff() {
      try {
        const response = await client.query({
          query: profileQuery,
          fetchPolicy: 'no-cache',
        });
        dispatch(updateProfile({ profile: response?.data?.myProfile }));
      } catch (err) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'fetch-profile',
            severity: 'danger',
            message: 'There was an error fetching your profile.',
          }),
        );
      }
    }
    doAsyncStuff();
  }, [client, dispatch]);
  useProfileUpdates();
  return (
    <Routes>
      <Route path="/users/:tab" element={<AllUsersPage />} />
      <Route path="/users/view/:_id/:tab" element={<ViewUser />} />
      <Route path="/bed-sensors" element={<AllBedSensorsPage />} />
      <Route
        path="/bed-sensors/view/:_id/:tab"
        element={<ViewBedSensorPage />}
      />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/dashboard" element={<DashboardPage />} />
      <Route path="/profile/:tab" element={<ProfilePage />} />
      <Route path="/sign-in" element={<Navigate to="/work-orders" replace />} />
      <Route path="/" element={<Navigate to="/work-orders" replace />} />
      <Route path="*" element={<DashboardPage />} />
    </Routes>
  );
}

export default MainRouter;
