import { Box, Card, Typography } from '@mui/joy';
import logo from '../../assets/long-bed-sensor-logo.svg';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import { useParams } from 'react-router-dom';

function PasswordResetSent() {
  const { username } = useParams();
  return (
    <PublicPageTemplate title="Success">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Theora Bed Sensor"
          style={{
            marginTop: 80,
            width: 400,
            maxWidth: '90vw',
            marginBottom: 40,
          }}
        />
        <Card sx={{ width: 600, maxWidth: '90vw', p: 4, mb: 8 }}>
          <Typography level="h1" sx={{ textAlign: 'center', mb: 2 }}>
            Password Reset Sent
          </Typography>
          <Typography level="h3" sx={{ textAlign: 'center' }}>
            {username}
          </Typography>
          <Typography sx={{ textAlign: 'center', mb: 3 }}>
            If your username exists, we've sent you an email with instructions
            to reset your password.
          </Typography>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default PasswordResetSent;
