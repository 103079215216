import { FetchPolicy, useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { BehaviorSubject, of } from 'rxjs';
import autocomplete from './autocomplete';
import { hashCode } from './utils';
import { RootState } from '../store/root';
import {
  incrementRemoteQuery,
  nextRemoteQuery,
  updateRemoteQuery,
} from '../store/query-slice';
import { showMessage } from '../store/snackbar-slice';

export interface SortBy {
  key: string;
  order: 'DESC' | 'ASC';
}

interface Props {
  query: any;
  extract: (data: any) => any;
  first: number;
  filters: any;
  sortBy?: SortBy[];
  fetchPolicy: FetchPolicy;
  queryId?: string;
}

export function useManyRemote<T, Q>({
  query,
  extract,
  first,
  filters,
  sortBy,
  fetchPolicy = 'no-cache',
  queryId = 'default',
}: Props) {
  const [loading, setLoading] = useState(false);
  const term$ = useRef<BehaviorSubject<string> | undefined>();
  const nextAfter = useRef<string | undefined>();
  const hasNextPage = useRef<boolean | undefined>();
  const _data = useRef<Q[]>([]);
  const [data, _setData] = useState<Q[]>(_data.current);
  const setData = useCallback((value: any) => {
    _data.current = value;
    _setData(value);
  }, []);

  const queryCode = useMemo(() => {
    const inputs = JSON.stringify({
      query,
      first,
      filters,
      sortBy,
    });
    const code = hashCode(inputs);
    return code;
  }, [query, first, filters, sortBy]);

  const { refetches, after } = useSelector((store: RootState) => {
    const update = store.queries[queryId]?.[queryCode] || {
      _id: queryCode,
      refetches: 0,
      after: undefined,
    };
    return update;
  }, shallowEqual);

  const search = useRef<string | undefined>();
  const apolloClient = useApolloClient();
  const abort = useRef<any>({});
  const last = useRef<string | undefined>();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      updateRemoteQuery(
        {
          _id: queryCode,
          refetches,
          after,
        },
        queryId,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, queryId, queryCode]);

  useEffect(() => {
    async function doAsyncStuff() {
      setLoading(true);
      if (abort.current[query]) {
        abort.current[query].abort();
      }
      abort.current[query] = new AbortController();
      try {
        const result = await apolloClient.query<T>({
          query,
          variables: {
            first,
            after,
            filters: {
              ...filters,
              search: search.current,
            },
            sortBy,
          },
          fetchPolicy,
          context: {
            fetchOptions: {
              signal: abort.current.signal,
            },
          },
        });
        const results = result && extract(result.data);
        if (results) {
          hasNextPage.current = results.pageInfo.hasNextPage;
          nextAfter.current = results.pageInfo.endCursor;
          if (after) {
            setData([
              ..._data.current,
              ...results.edges.map((edge: any) => edge.node),
            ]);
          } else {
            setData(results.edges.map((edge: any) => edge.node));
          }
        }
      } catch (err: any) {
        if (err.message.includes('abort')) {
          console.log('Fetch aborted.');
        } else {
          console.error(err);
          dispatch(
            showMessage({
              _id: `${hashCode}`,
              severity: 'danger',
              message: err.message,
            }),
          );
        }
      } finally {
        abort.current[query] = undefined;
        setLoading(false);
      }
    }

    const inputs = JSON.stringify({
      refetches,
      after,
      queryCode,
    });
    if (inputs !== last.current) {
      last.current = inputs;
      doAsyncStuff();
    }
  }, [
    filters,
    first,
    setData,
    refetches,
    apolloClient,
    query,
    sortBy,
    extract,
    fetchPolicy,
    queryCode,
    after,
    dispatch,
  ]);

  useEffect(() => {
    term$.current = new BehaviorSubject('__init__');
    term$.current
      .pipe(
        autocomplete(100, (term: string) => {
          if (term !== '__init__') {
            hasNextPage.current = undefined;
            nextAfter.current = undefined;
            search.current = term || undefined;
            dispatch(incrementRemoteQuery(queryCode, queryId));
          }
          return of();
        }),
      )
      .subscribe();
  }, [setData, dispatch, queryCode, queryId]);

  const _search = useCallback((term: string) => {
    if (term !== undefined) {
      term$.current?.next(term);
    }
  }, []);

  const refetch = useCallback(() => {
    dispatch(incrementRemoteQuery(queryCode, queryId));
  }, [dispatch, queryCode, queryId]);

  return {
    data,
    hasNextPage: hasNextPage.current,
    loading,
    next: () => {
      if (hasNextPage.current && nextAfter.current) {
        dispatch(
          nextRemoteQuery(
            { _id: queryCode, after: nextAfter.current },
            queryId,
          ),
        );
      }
    },
    search: _search,
    refetch,
    reset: async () => {
      await apolloClient.resetStore();
      dispatch(incrementRemoteQuery(queryCode, queryId));
    },
  };
}
