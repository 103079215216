import { useMemo } from 'react';

function useDiscussionObjectId(filters: any) {
  const objectId = useMemo(() => {
    if (filters.userId) {
      return filters.userId;
    }
  }, [filters]);
  return objectId;
}

export default useDiscussionObjectId;
