import { Box, Button, Sheet, Skeleton, Table, Typography } from '@mui/joy';
import {
  MutableRefObject,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { SortBy, SortByDirection } from '../../__generated__/graphql';
import useSafeState from '../../shared/use-safe-state';
import MyTablePagination from './MyTablePagination';
import SearchBox from './SearchBox';
import useTableFilters, { AllFilter } from './TableFilters';
import TableLoadMore from './TableLoadMore';
import TableSortableHeadCell from './TableSortableHeadCell';

export interface AllColumn {
  key: string;
  title: string;
  component: (row: any) => ReactElement;
  sortable: boolean;
  width?: string | number;
  style?: (row: any) => any;
}

interface Props {
  initialOrder: SortBy;
  rows: any[];
  sortBy: MutableRefObject<SortBy[]>;
  refetch: () => void;
  parentFilters?: any;
  filters: MutableRefObject<any>;
  allFilters: AllFilter[];
  search?: (s: string) => void;
  allColumns: AllColumn[];
  hasNextPage: boolean;
  next: () => void;
  loading?: boolean;
  hasFullText?: boolean;
  tableHeader?: any;
}

function MyTable({
  initialOrder,
  rows,
  sortBy,
  refetch,
  parentFilters,
  filters,
  allFilters,
  search,
  allColumns,
  hasNextPage,
  next,
  loading,
  hasFullText,
  tableHeader,
}: Props) {
  const [order, setOrder, _order] = useSafeState<SortBy>(initialOrder);
  const onRequestSort = (col: string) => {
    const isAsc =
      _order.current.key === col &&
      _order.current.order === SortByDirection.Asc;
    setOrder({
      order: isAsc ? SortByDirection.Desc : SortByDirection.Asc,
      key: col,
    });
    sortBy.current = [_order.current];
    refetch();
  };
  const [pagination, setPagination] = useState({
    page: 0,
    rowsPerPage: 50,
  });
  const [startRow, endRow, emptyRows] = useMemo(() => {
    const start = pagination.page * pagination.rowsPerPage;
    const end = start + pagination.rowsPerPage;
    const empty =
      pagination.page > 0
        ? Math.max(
            0,
            (1 + pagination.page) * pagination.rowsPerPage - rows.length,
          )
        : 0;
    return [start, end, empty];
  }, [pagination, rows]);

  const onFilterChange = useCallback(
    (newFilters: any) => {
      filters.current = {
        ...newFilters,
        ...(parentFilters || {}),
      };
      refetch();
    },
    [refetch, filters, parentFilters],
  );

  const {
    header: filtersHeader,
    button: filtersButton,
    modal: filtersModel,
  } = useTableFilters({
    _filters: filters,
    onFilterChange,
    allFilters,
  });

  const columnWidths = useMemo(() => {
    return allColumns.reduce((prev: any, curr: AllColumn, index) => {
      if (curr.width !== undefined) {
        prev[`& thead th:nth-child(${index + 1})`] = { width: curr.width };
      }
      return prev;
    }, {});
  }, [allColumns]);
  const onSearch = useCallback(
    (term: string) => {
      if (search) {
        if (hasFullText) {
          _order.current = {
            order: SortByDirection.Desc,
            key: 'score',
          };
          sortBy.current = [_order.current];
        }
        search(term);
        setPagination({
          page: 0,
          rowsPerPage: 50,
        });
      }
    },
    [_order, search, sortBy, hasFullText],
  );
  return (
    <>
      <Box sx={{ display: 'flex', mb: 2, alignItems: 'center' }}>
        {search && <SearchBox onSearch={onSearch} />}
        {allFilters.length > 0 ? filtersButton : null}
        <Box sx={{ flex: 1 }} />
        <Button
          variant="outlined"
          onClick={() => {
            refetch();
          }}
        >
          Refresh
        </Button>
      </Box>
      {tableHeader}
      <Sheet
        sx={{
          p: 1,
          pl: 0,
          // borderWidth: 1,
          borderRadius: 8,
          // borderStyle: 'solid',
          // borderColor: 'var(--joy-palette-divider)',
          overflow: 'auto',
        }}
        variant="outlined"
      >
        {filtersHeader}
        <Table
          borderAxis="xBetween"
          color="neutral"
          variant="plain"
          // noWrap
          // sx={{ minWidth: 1000, '& thead th:nth-child(2)': { width: '600px' } }}
          sx={{
            ...columnWidths,
            // '& tr > *:first-child': {
            //   position: 'sticky',
            //   left: 0,
            //   boxShadow: '1px 0 var(--TableCell-borderColor)',
            //   bgcolor: 'background.surface',
            // },
          }}
        >
          <thead>
            <tr>
              {allColumns.map((col: any, index: number) => (
                <th
                  key={index}
                  style={{
                    zIndex: index === 0 ? 1 : undefined,
                    backgroundColor:
                      index === 0
                        ? 'var(--TableCell-headBackground)'
                        : undefined,
                  }}
                >
                  {col.sortable ? (
                    <TableSortableHeadCell
                      active={order.key === col.key}
                      _id={col.key}
                      label={col.title}
                      onRequestSort={onRequestSort}
                      order={order.order}
                    />
                  ) : (
                    col.title
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 && (
              <>
                {loading ? (
                  Array(10)
                    .fill(0)
                    .map((_, index) => (
                      <tr key={index}>
                        {allColumns.map((col: any, index: number) => (
                          <td key={index}>
                            <Skeleton
                              variant="rectangular"
                              height={24}
                              sx={{ mb: 1, mt: 1 }}
                            />
                          </td>
                        ))}
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={allColumns.length}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          p: 6,
                        }}
                      >
                        <Typography sx={{ textAlign: 'center' }}>
                          Nothing to see here.
                        </Typography>
                      </Box>
                    </td>
                  </tr>
                )}
              </>
            )}
            {rows.slice(startRow, endRow).map((row) => (
              <tr key={row._id}>
                {allColumns.map((col: any) => (
                  <td key={col.key} style={col.style?.(row)}>
                    {col.component(row)}
                  </td>
                ))}
              </tr>
            ))}
            {emptyRows > 0 && (
              <tr
                style={
                  {
                    height: `calc(${emptyRows} * 40px)`,
                    '--TableRow-hoverBackground': 'transparent',
                  } as React.CSSProperties
                }
              >
                <td colSpan={allColumns.length} aria-hidden />
              </tr>
            )}
          </tbody>
        </Table>
      </Sheet>
      <MyTablePagination
        numRows={rows.length}
        pagination={pagination}
        setPagination={setPagination}
      />
      <TableLoadMore
        numRows={rows.length}
        hasMore={!!hasNextPage}
        loadMore={next}
      />
      {filtersModel}
    </>
  );
}

export default MyTable;
