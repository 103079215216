import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import {
  THEME_ID as MATERIAL_THEME_ID,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
} from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import RootRouter from './routers/RootRouter';

const materialTheme = materialExtendTheme();

const router = createBrowserRouter([{ path: '*', Component: RootRouter }]);

declare module '@mui/joy/styles' {
  interface ColorPalettePropOverrides {
    // apply to all Joy UI components that support `color` prop
  }

  interface Palette {
    // this will make the node `secondary` configurable in `extendTheme`
    // and add `secondary` to the theme's palette.
  }
}

const theme = extendTheme({
  // fontFamily: {
  //   // display: 'Mohave Variable', // applies to `h1`–`h4`
  //   // body: 'Noto Sans', // applies to `title-*` and `body-*`
  // },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#e0f9ec',
          100: '#b3eed1',
          200: '#7ce3b3',
          300: '#16d993',
          400: '#00cf7a',
          500: '#00c464',
          600: '#00b459',
          700: '#00a14c',
          800: '#008f40',
          900: '#006e2a',
        },
        success: {
          50: '#e6f6e8',
          100: '#c4e9c8',
          200: '#9ddba4',
          300: '#72ce7f',
          400: '#4fc362',
          500: '#23b845',
          600: '#17a83c',
          700: '#009630',
          800: '#008525',
          900: '#006511',
        },
        danger: {
          50: '#ffeaee',
          100: '#ffcbd2',
          200: '#f59599',
          300: '#ed6a71',
          400: '#f9424d',
          500: '#ff2731',
          600: '#f01831',
          700: '#de012b',
          800: '#d10023',
          900: '#c30015',
        },
        warning: {
          50: '#fffae6',
          100: '#fef2c0',
          200: '#fcec97',
          300: '#f9e46e',
          400: '#f5dd4d',
          500: '#f2d828',
          600: '#e2c624',
          700: '#ceb11e',
          800: '#ba9a19',
          900: '#99750f',
        },
      },
    },
  },
});

function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RouterProvider router={router} />
        </LocalizationProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
