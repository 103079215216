import { gql } from '@apollo/client';
import {
  AllBedSensorsQueryQuery,
  BedSensorQueryQuery,
} from '../../__generated__/graphql';

export const allBedSensorsQuery = gql`
  query AllBedSensorsQuery(
    $first: Int
    $after: String
    $filters: BedSensorFilters
    $sortBy: [SortBy]
  ) {
    allBedSensors(
      first: $first
      after: $after
      filters: $filters
      sortBy: $sortBy
    ) {
      edges {
        cursor
        node {
          _id
          name
          serialNo
          macAddress
          fwVersion
          lastTimestampMs
          notes
          createdAt
          updatedAt
          deletedAt
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`;

export const extractAllBedSensors = (data: AllBedSensorsQueryQuery) =>
  data.allBedSensors;

export const bedSensorQuery = gql`
  query BedSensorQuery($_id: ID!) {
    bedSensor(_id: $_id) {
      _id
      name
      serialNo
      macAddress
      fwVersion
      lastTimestampMs
      notes
      createdAt
      updatedAt
      deletedAt
      createdAt
      updatedAt
      deletedAt
    }
  }
`;

export const extractBedSensor = (data: BedSensorQueryQuery) => data.bedSensor;
