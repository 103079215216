import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import PasswordHelpPage from '../pages/auth/PasswordHelpPage';
import PasswordResetPage from '../pages/auth/PasswordResetPage';
import PasswordResetSent from '../pages/auth/PasswordResetSent';
import PasswordResetSuccessPage from '../pages/auth/PasswordResetSuccessPage';
import SignInPage from '../pages/auth/SignInPage';
import AcceptUserInvite from '../pages/user-invites/AcceptUserInvite';
import UserInviteAccepted from '../pages/user-invites/UserInviteAccepted';

function PublicRouter() {
  return (
    <Routes>
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/password-help" element={<PasswordHelpPage />} />
      <Route
        path="/password-reset-sent/:username"
        element={<PasswordResetSent />}
      />
      <Route
        path="/password-reset/success/:token"
        element={<PasswordResetSuccessPage />}
      />
      <Route path="/password-reset/:token" element={<PasswordResetPage />} />
      <Route path="/invites/:token" element={<AcceptUserInvite />} />
      <Route path="/invite/accepted/:token" element={<UserInviteAccepted />} />
      <Route path="*" element={<HomePage />} />
    </Routes>
  );
}

export default PublicRouter;
