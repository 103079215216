import { gql, useMutation } from '@apollo/client';
import { Box, Button, Card, Typography } from '@mui/joy';
import { useCallback, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/t-logo.svg';
import PasswordFieldElement from '../../components/PasswordFieldElement';
import PublicPageTemplate from '../../components/PublicPageTemplate';
import TextFieldElement from '../../components/TextFieldElement';
import { saveToken } from '../../store/auth-slice';
import { showMessage } from '../../store/snackbar-slice';

const forgotMyPasswordMutation = gql`
  mutation ForgotMyPassword($username: String!) {
    webForgotMyPassword(username: $username)
  }
`;

type Inputs = {
  username: string;
};

function PasswordHelpPage() {
  const [loading, setLoading] = useState(false);
  const [forgotMyPassword] = useMutation(forgotMyPasswordMutation);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      username: '',
    },
  });
  // const onSubmit: SubmitHandler<Inputs> = (data) => console.log(data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Inputs> = useCallback(
    async (values) => {
      setLoading(true);
      try {
        const username = values.username.trim().toLowerCase();
        await forgotMyPassword({
          variables: {
            username,
          },
        });
        dispatch(
          showMessage({
            _id: 'password-help',
            severity: 'success',
            message: 'Success',
          }),
        );
        navigate(`/password-reset-sent/${username}`);
      } catch (err: any) {
        console.error(err);
        dispatch(
          showMessage({
            _id: 'password-help',
            severity: 'danger',
            message: 'We apologize, an error occurred.',
          }),
        );
      }
      setLoading(false);
    },
    [forgotMyPassword, dispatch],
  );
  return (
    <PublicPageTemplate title="Password Help">
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          minHeight: '100%',
          flexDirection: 'column',
        }}
      >
        <img
          src={logo}
          alt="Theora Bed Sensor"
          style={{
            marginTop: 120,
            width: 160,
            maxWidth: '90vw',
            marginBottom: 24,
          }}
        />
        <Card style={{ width: 500, maxWidth: '90vw', padding: 32 }}>
          <Typography level="h1" sx={{ textAlign: 'center' }}>
            Password Help
          </Typography>
          <Typography sx={{ textAlign: 'center' }}>
            Enter your username below and we'll send you an email with a link to
            create a new password.
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TextFieldElement
              name="username"
              label="Username"
              control={control}
              rules={{ required: 'This field is required' }}
            />
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                type="submit"
                loading={loading}
                variant="outlined"
                size="lg"
              >
                Submit
              </Button>
            </Box>
          </form>
        </Card>
      </Box>
    </PublicPageTemplate>
  );
}

export default PasswordHelpPage;
